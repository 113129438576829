import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';
import get from 'lodash/get';

export default function Select(props) {
  const {
    disabled,
    error,
    fullWidth,
    helperText,
    id,
    items,
    keyExtractor,
    label,
    labelProp,
    menuProps,
    multiple,
    name,
    onBlur,
    onChange,
    readOnly,
    renderItem,
    renderValue,
    size,
    sx,
    value,
    valueProp,
    variant,
    disableUnderline,
  } = props;

  return (
    <FormControl
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      sx={sx}
      variant={variant}
    >
      <InputLabel id={`${id}-label`} size={size}>
        {label}
      </InputLabel>
      <MuiSelect
        disabled={disabled}
        disableUnderline={disableUnderline}
        label={label}
        labelId={`${id}-label`}
        MenuProps={menuProps}
        multiple={multiple}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        readOnly={readOnly}
        renderValue={renderValue}
        size={size}
        value={value}
      >
        {(items ?? []).map((item, index) => {
          const key = keyExtractor ? keyExtractor({
            item,
            index,
          }) : item.id;

          return renderItem
            ? renderItem({
              item,
              index,
            })
            : (
              <MenuItem
                key={key}
                value={item[valueProp ?? 'id']}
              >
                {get(item, labelProp ?? 'attributes.name')}
              </MenuItem>
            );
        })}
      </MuiSelect>
      {helperText && (
        <FormHelperText>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
